// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #28a745;
$teal: #4dc0b5;
$cyan: #6cb2eb;

//

$color_blue: #2a73af;

$color_primary: #2e3264;
$color_secondary: #ffcc00;
$color_secondary_light: rgba(255, 204, 0, 0.2);
$color_secondary_light_hover: rgba(255, 204, 0, 0.3);

$color_info: #17a2b8;
$color_info_light: rgba(23, 162, 184, 0.2);

$color_light: #f1f3f6;
$color_light_secondary: #a4a6bd;

$color_danger: #dc3545;
$color_danger_light: rgba(220, 53, 70, 0.2);
$color_danger_light_hover: rgba(220, 53, 70, 0.3);

$color_green: #28a745;
$color_green_light: rgba(40, 167, 69, 0.2);
$color_green_light_hover: rgba(40, 167, 69, 0.3);

$color_grey: #545b62;
$color_grey_light: #545b627e;

$color_grey_light: rgba(84, 91, 98, 0.1);
$color_grey_light_hover: rgba(84, 91, 98, 0.2);
