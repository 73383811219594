// Variables
@import './variables';

// SCROLLBARS

:root {
  --scrollbar-size: 0.375rem;
  --scrollbar-minlength: 2.5rem; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: white;
  --scrollbar-color: rgba(46, 50, 100, 0.3);
  --scrollbar-color-hover: rgba(46, 50, 100, 0.6);
  --scrollbar-color-active: rgb(46, 50, 100, 0.6);
}

.layout-cell {
  overscroll-behavior: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: var(--scrollbar-ff-width);
}

/* This class controls what elements have the new fancy scrollbar CSS */
.layout-scrollbar {
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
}
.layout-scrollbar::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}
.layout-scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}
.layout-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 3px;
}
.layout-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color-hover);
}
.layout-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-color-active);
}
.layout-scrollbar::-webkit-scrollbar-thumb:vertical {
  min-height: var(--scrollbar-minlength);
}
.layout-scrollbar::-webkit-scrollbar-thumb:horizontal {
  min-width: var(--scrollbar-minlength);
}
/* .layout-scrollbar::-webkit-scrollbar-corner {
  display: none;
}
.layout-scrollbar::-webkit-scrollbar-button {
  display: none;
} 
 */

.layout-cell_x {
  overscroll-behavior: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: var(--scrollbar-ff-width);
}

/* This class controls what elements have the new fancy scrollbar CSS */
.layout-scrollbar_x {
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
  padding-bottom: 400px;
  margin-bottom: -400px;
}
.layout-scrollbar_x::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}
.layout-scrollbar_x::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}
.layout-scrollbar_x::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 3px;
}
.layout-scrollbar_x::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color-hover);
}
.layout-scrollbar_x::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-color-active);
}
.layout-scrollbar_x::-webkit-scrollbar-thumb:vertical {
  min-height: var(--scrollbar-minlength);
}
.layout-scrollbar_x::-webkit-scrollbar-thumb:horizontal {
  min-width: var(--scrollbar-minlength);
}
/* .layout-scrollbar_x::-webkit-scrollbar-corner {
  display: none;
}
.layout-scrollbar_x::-webkit-scrollbar-button {
  display: none;
} 
 */

// BURGER MENU

// #App {
//   font-family: sans-serif;

//   /* Give app full page to work with */
//   height: 100vh;
// }

// #page-wrap {
//   text-align: center;

//   /* Prevent sidebar from showing a scrollbar on page */
//   overflow: auto;
// }

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  &:hover,
  &:focus {
    outline: none;
  }
}

.rdtPicker {
  margin-left: -140px;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 24px;
  height: 24px;
  // right: 32px;
  // top: 32px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  width: 24px !important;
  height: 3px !important;
  background: $color_light;
}

.bm-burger-bars-hover {
  background: $color_light !important;
  opacity: 1 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
  // height: 24px;
  // width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: $color_light;
  font-size: 1.15em;
}

.bm-menu-wrap {
  top: 96px;
  height: calc(100vh - 96px) !important;
}

/* Wrapper for item list */
.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 96px;
  left: 0;
}

// DATETIME PICKER

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  max-width: 320px;
  width: auto;
  padding: 4px;
  margin-top: 1px;
  z-index: 1000 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

// DATEPICKER REACT DATETIME

.rdt input {
  border: 1px solid #ced4da;
}

// CONTAINER

.container {
  @media (min-width: 1440px) {
    max-width: 1240px !important;
  }
}

.container_padding {
  padding-top: 96px;
  padding-bottom: 48px;
  min-height: 100vh;
  @media (min-width: 768px) {
    padding-bottom: 80px;
  }
}

.container_padding_top {
  padding-top: 96px;
  min-height: 100vh;
  width: 100vw;
}

.container_no_padding {
  @media (max-width: 576px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// PLACEHOLDER SELECTORS

%button {
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  // border-radius: 10px;

  box-shadow: none !important;
  border-radius: 0.25rem;
  height: 45px;
}

%font_noto {
  font-family: Noto Sans JP;
  // line-height: 1;
}

%link {
  text-decoration: none !important;
  &:hover &:focus {
    text-decoration: none !important;
  }
}

%list {
  list-style: none;
}

%image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// CARD

.card {
  border: none !important;
}

// INPUT

input {
  height: 45px !important;
  font-size: 16px !important;
  outline: 0;
}

input[type='radio'],
input[type='checkbox'] {
  height: auto !important;
}

select {
  height: 45px !important;
}

.PhoneInput {
  height: 45px !important;
}

.PhoneInputInput {
  height: auto !important;
}

// PHONE INPUT

.PhoneInputInput {
  border: 0;
  outline: none;
}

// STANDART FONT

.font_size_16 {
  font-size: 16px;
}

.font_standart_family {
  font-family: Noto Sans JP;
}

.font_standart {
  @extend %font_noto;
  color: black;
}

.font_standart_primary {
  @extend %font_noto;
  color: $color_primary;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.font_standart_primary_small {
  @extend %font_noto;
  color: $color_primary;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5rem;
}

.font_standart_light_small {
  @extend %font_noto;
  color: $color_light;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
}

.add_decoration {
  color: $color_blue !important;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color_blue !important;
  }
}

.remove_decoration {
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

// COLORS

.color_primary {
  color: $color_primary;
}

.color_secondary {
  color: $color_secondary;
}

.color_light {
  color: $color_light;
}

.color_light_secondary {
  color: $color_light_secondary;
}

.color_green {
  color: $green;
}

.color_danger {
  color: $color_danger;
}

// BACKGROUND

.background_primary {
  background-color: $color_primary;
}

.background_light {
  background-color: $color_light;
}

.background_light_secondary {
  background-color: $color_light_secondary;
}

.background_warning {
  background-color: $yellow;
}

.background_danger {
  background-color: $color_danger;
}

.background_info_light {
  background-color: $color_info_light;
}

.background_danger_light {
  background-color: $color_danger_light;
  &:hover {
    background-color: $color_danger_light_hover !important;
  }
}

.background_secondary_light {
  background-color: $color_secondary_light;
  &:hover {
    background-color: $color_secondary_light_hover !important;
  }
}

.background_gray_light {
  background-color: $color_grey_light;
  &:hover {
    background-color: $color_grey_light_hover !important;
  }
}

// LOGO

.clustr_logo {
  height: 24px;
  @media (min-width: 420px) {
    height: 32px;
  }
}

// BUTTONS

.button_common {
  @extend %button;
  @extend %font_noto;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: 500;
  &:focus,
  :active {
    @extend %button;
  }
}

.button_common_small {
  @extend %button;
  @extend %font_noto;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: 500;
  // padding-left: 1rem;
  // padding-right: 1rem;
  &:focus,
  :active {
    @extend %button;
  }
}

.button_common_small_delete {
  @extend %font_noto;
  height: 45px;
  border-radius: 0.25rem;
  font-style: normal;
  font-weight: 500;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.button_common_no_padding {
  @extend %button;
  @extend %font_noto;
  border: none;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  &:focus {
    @extend %button;
  }
}

.button_login {
  @extend %button;
  @extend %font_noto;
  border: none;
  background-color: $color_secondary;
  color: $color_primary;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (min-width: 420px) {
    font-weight: 700;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.button_add {
  @extend %button;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
}

.button_sm_secondary {
  @extend %button;
  background-color: $color_secondary;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.button_md_secondary {
  @extend %button;
  background-color: $color_secondary;
  padding: 1rem 4rem;
  @media (min-width: 768px) {
    padding: 1rem 2.625rem;
  }
  @media (min-width: 992px) {
    padding: 1rem 4rem;
  }
}

.button_sm_primary {
  @extend %button;
  background-color: $color_primary;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.button_md_primary {
  @extend %button;
  background-color: $color_primary;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
}

.button_md_primary_no_radius {
  background-color: $color_primary;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.text_button_primary {
  @extend %font_noto;
  color: $color_primary;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
}

.text_button_light {
  @extend %font_noto;
  color: $color_light;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
}

.button_view_all {
  @extend %font_noto;
  white-space: pre;
  color: $color_primary;
  background-color: transparent;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375rem;
}

.button_how_it_works {
  @extend %font_noto;
  white-space: pre;
  color: $color_light;
  background-color: transparent;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none !important;
  &:hover,
  &:focus {
    text-decoration: none !important;
    color: $color_light !important;
  }
}

.button_how_it_works_primary {
  @extend %font_noto;
  white-space: pre;
  color: $color_primary;
  background-color: transparent;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none !important;
  &:hover,
  &:focus {
    text-decoration: none !important;
    color: $color_primary !important;
  }
}

%button_circle {
  @extend %link;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color_primary;
  border-radius: 50%;
  width: 86px;
  height: 86px;
  &:hover,
  &:focus {
    color: $color_light;
  }
  @media (min-width: 360px) {
    width: 100px;
    height: 100px;
  }
  @media (min-width: 480px) {
    width: 120px;
    height: 120px;
  }
  @media (min-width: 992px) {
    width: 160px;
    height: 160px;
  }
}

.button_circle_green {
  @extend %link;
  @extend %button_circle;
  background-color: transparent;
  border: 8px solid $green;
  transition: background-color 200ms ease-in, color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in, color 200ms ease-in;
    background-color: $green;
  }
}

.button_circle_warning {
  @extend %link;
  @extend %button_circle;
  background-color: transparent;
  border: 8px solid $color_secondary;
  transition: background-color 200ms ease-in, color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in, color 200ms ease-in;
    background-color: $color_secondary;
  }
}

.button_circle_danger {
  @extend %link;
  @extend %button_circle;
  background-color: transparent;
  border: 8px solid $color_danger;
  transition: background-color 200ms ease-in, color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in, color 200ms ease-in;
    background-color: $color_danger;
  }
}

.button_circle_number {
  @extend %font_noto;
  @extend %link;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
}

.button_circle_text {
  @extend %font_noto;
  @extend %link;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  @media (min-width: 992px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

%pill_round {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_primary;
  border-radius: 0.25rem;
  width: 100%;
  height: auto;
  padding: 14px;
}

.pill_round_grey {
  @extend %pill_round;
  background-color: transparent;
  border: 6px solid $color_grey_light_hover;
  transition: background-color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in;
    background-color: $color_grey_light;
  }
}

.pill_round_green {
  @extend %pill_round;
  background-color: transparent;
  border: 6px solid $color_green_light_hover;
  transition: background-color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in;
    background-color: $color_green_light;
  }
}

.pill_round_yellow {
  @extend %pill_round;
  background-color: transparent;
  border: 6px solid $color_secondary_light_hover;
  transition: background-color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in;
    background-color: $color_secondary_light;
  }
}

.pill_round_red {
  @extend %pill_round;
  background-color: transparent;
  border: 6px solid $color_danger_light_hover;
  transition: background-color 200ms ease-in;
  &:hover,
  &:focus {
    transition: background-color 200ms ease-in;
    background-color: $color_danger_light;
  }
}

.pill_round_text {
  @extend %font_noto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  @media (min-width: 992px) {
    font-size: 1rem;
  }
}

// IMAGES

.image_standart {
  @extend %image;
}

.image_rounded {
  @extend %image;
  border-radius: 10px;
}

.image_rounded_shadow {
  @extend %image;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// TEXT

.link_black {
  &:hover {
    color: black;
  }
  &:focus {
    color: black;
  }
}

.link_primary {
  @extend %link;
  &:hover {
    color: $color_primary;
  }
  &:focus {
    color: $color_primary;
  }
}

.link_light {
  @extend %link;
  &:hover {
    color: $color_light;
  }
  &:focus {
    color: $color_light;
  }
}

.list_header {
  @extend %list;
}

// UNDERLINE

.underline_light {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color_light;
    width: 100%;
    height: 2px;
  }
}

// FORM CONTROL

.form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.card_tab {
  @extend %font_noto;
  background-color: $color_light;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  padding: 0.5rem 0.875rem;
  border-radius: 10px;
}

.eye {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(calc(-100% - 8px), 15px);
  background-image: url(../images/eye.svg);
  background-size: contain;
  width: 16px;
  height: 16px;
}
